<template>
  <FormModal class="form-modal--intro form-modal--secondary">
    <template #body>
      <div class="form-modal__logo-container">
        <InlineSvg
          class="form-modal__logo"
          src="images/logo.svg"
          :alt="concept"
        />
      </div>
      <div class="form-modal__title">
        Wir werden Ihnen jetzt ein paar Fragen stellen. Dies ermöglicht uns,
        Makler zu finden, die zu Ihnen passen.
      </div>
    </template>

    <template #footer>
      <t-btn-link
        id="footerNextButton"
        class="footer__next-btn"
        to="/angebotsanfragen/art-der-anfrage"
      >
        Weiter
      </t-btn-link>
    </template>
  </FormModal>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'

export default {
  components: {
    FormModal,
    InlineSvg,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Fragebogen starten',
      headDescription:
        'Wir werden Ihnen jetzt ein paar Fragen stellen. Dies ermöglicht uns, Makler zu finden, die zu Ihnen passen.',
      path: '/angebotsanfragen/start',
      progressValue: 0,
      checkoutStep: 1,
    }
  },
}
</script>
